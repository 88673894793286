import axios from "axios";

const frontend_api = process.env.REACT_APP_API_URL as string;
// Create an Axios instance with the base URL and common headers
const axiosInstance = axios.create({
  baseURL: frontend_api + "/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add a request interceptor to include JWT token and CSRF token in the headers
// axiosInstance.interceptors.request.use(
//   (request) => {
//     if (request.method && request.method.toUpperCase() !== "GET") { // Exclude GET requests
//       const csrfToken = getCsrfTokenFromCookie();
//       if (csrfToken) {
//         request.headers["X-XSRF-TOKEN"] = csrfToken;
//       }
//     }
//     // Retrieve the CSRF token from the cookie (if available)
//     return request;
//   },
//   (error) => Promise.reject(error),
// );

// Function to retrieve the CSRF token from cookies
// function getCsrfTokenFromCookie(): string | null {
//   const match = document.cookie.match(new RegExp("(^| )XSRF-TOKEN=([^;]+)"));
//   return match ? match[2] : null;
// }

// Add a response interceptor to handle different status errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    // Successful response - just return the response data
    return response;
  },
  (error) => {
    if (error.response) {
      // Server responded with a status other than 2xx
      const status = error.response.status;

      // Handle specific status codes
      switch (status) {
        case 400:
          // Bad Request - Invalid input or data
          break;
        case 401:
          // Unauthorized - Could be login required
          break;
        case 403:
          // Forbidden - Access denied
          break;
        case 409:
          // Conflict - Usually when email already exists
          break;
        case 422:
          // Unprocessable Entity - Validation errors (e.g., weak password)
          break;
        case 500:
          // Internal Server Error - Server-side issue
          break;
        case 503:
          // Service Unavailable - Temporary downtime
          break;
        default:
          // For any other unexpected status
          break;
      }
    } else if (error.request) {
      // The request was made, but no response was received
      alert("No response received from the server.Try again later.");
    }
    // Reject the error to handle it further in individual components if needed
    return Promise.reject(error);
  },
);

export default axiosInstance;
