import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Home.css";
import TitleText from "src/components/TitleText/TitleText";
import SubText from "src/components/SubText/SubText";
import SubTitle from "src/components/SubTitle/SubTitle";
import CardHome from "src/components/CardHome/CardHome";
import Slider from "src/components/Slider/Slider";
import { PromisePageContext } from "src/machines/pageMachine";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "src/axiosInstance";
import Logo from "src/icons/Logo";
import InputText from "src/components/InputText/InputText";

const Home = () => {
  let { chargerName } = useParams();
  const navigate = useNavigate();
  chargerName = chargerName || localStorage.getItem("chargerName") || "";
  if (
    !chargerName ||
    chargerName.trim() === "" ||
    chargerName === "undefined" ||
    chargerName === "null"
  ) {
    navigate("/charger");
  } else {
    localStorage.setItem("chargerName", chargerName);
  }

  let connectorId = 1; // Set to 1 right now as all our chargers have 1 connector, will be changed later
  const location = useLocation();
  const { paymentIntentId, guestId } = location.state || {};
  if (paymentIntentId !== undefined && paymentIntentId !== "") {
    localStorage.setItem("paymentIntentId", paymentIntentId);
  }
  if (guestId !== undefined && guestId !== "") {
    localStorage.setItem("guestId", guestId);
  }
  let paymentIntent = paymentIntentId;

  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  //const [isElementInCenter, setIsElementInCenter] = useState(false);
  const { page, pageSend } = useContext(PromisePageContext);
  const [chargerId, setChargerId] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [hasStopped, setHasStopped] = useState(false);
  const [selectedElement, setSelectedElement] = useState<number>(0);
  const [sessionId, setSessionId] = useState<string>("");
  const [timeElapsed, setTimeElapsed] = useState<number>(0); // Time in seconds
  const [customerID, setCustomerID] = useState<string>("");
  const [authCode, setAuthCode] = useState<string>(""); // State for authorization code
  const [authMessage, setAuthMessage] = useState<string>(""); // State for success/error messages

  useEffect(() => {
    const fetchCharger = async () => {
      try {
        const response = await axiosInstance.get(`/chargers/${chargerName}`);
        const data = response.data;
        setChargerId(data.ID);
        localStorage.setItem("chargerId", data.ID);
        const vendor = data.ChargePointVendor.toLowerCase();
        const updatedImage = vendor.includes("teltonika")
          ? "/image/teltonika.jpg"
          : vendor.toLowerCase().includes("fractal")
            ? "/image/fractal2.png"
            : vendor.toLowerCase().includes("elmec")
              ? "/image/evduty.png"
              : "/image/fractal2.png";
        // Update charger state
        const chargerData = {
          chargerId: data.ID,
          name: chargerName,
          status: data.status,
          image: updatedImage,
        };
        setCharger([chargerData]);

        // Update chargerId state
      } catch (error: any) {
        const status = error.response?.status;
        switch (status) {
          default:
            setAuthMessage("Charger not found. Please scan again.");
            // navigate("/404", { state: { chargerId: chargerName } });
            break;
        }
      }
    };

    if (chargerName !== undefined) {
      fetchCharger(); // Run fetchCharger only if the chargerName is new
      localStorage.setItem("chargerName", chargerName); // Update localStorage with the new chargerName
    }
  }, [chargerId, chargerName, navigate]);

  const [charger, setCharger] = useState<any[]>([
    {
      id: 1,
      name: "My Charger",
      rate: "1.5 CAD / hr",
      status: "Available",
      image: chargerId.toLowerCase().includes("tel")
        ? "/image/teltonika.jpg"
        : chargerId.toLowerCase().includes("fr")
          ? "/image/fractal2.png"
          : chargerId.toLowerCase().includes("ev")
            ? "/image/evduty.png"
            : "/image/fractal2.png", // fallback image if none of the above matches
    },
  ]);

  const styles = {
    backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
    color: page.context.darkMode ? "#FFFFFF" : "#000000",
  };
  const checkStripeAccount = useCallback(async () => {
    await axiosInstance
      .get("/payments/customer")
      .then((response) => {
        if (response.data.status === "success") {
          setCustomerID(response.data.customerID);
          return true;
        } else {
          navigate("/payment", { state: { userLoggedIn: true } });
        }
      })
      .catch((error) => {
        navigate("/payment", { state: { userLoggedIn: true } });
      });
  }, [navigate]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (active) {
      const startTime = Date.now();

      // Update the elapsed time every second
      intervalId = setInterval(() => {
        const currentTime = Date.now();
        setTimeElapsed(Math.floor((currentTime - startTime) / 1000)); // Time in seconds
      }, 1000);
    }

    // Cleanup the interval when the component is unmounted or stopped
    return () => clearInterval(intervalId);
  }, [active]);

  // Format time as hours, minutes, and seconds
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  useEffect(() => {}, [selectedElement]);

  useEffect(() => {
    const handleScroll = () => {
      if (cardsContainerRef.current) {
        const container = cardsContainerRef.current;
        const containerWidth = container.offsetWidth;
        const scrollLeft = container.scrollLeft;
        const centerPosition = containerWidth / 2;
        const cards = container.getElementsByClassName(
          "CardHome-container",
        ) as HTMLCollectionOf<HTMLElement>;
        let elementInCenterIndex = null;

        for (let index = 0; index < cards.length; index++) {
          const card = cards[index];
          const cardLeft = card.offsetLeft;
          const cardWidth = card.offsetWidth;

          if (
            cardLeft <= centerPosition + scrollLeft &&
            cardLeft + cardWidth >= centerPosition + scrollLeft
          ) {
            elementInCenterIndex = index;
            break;
          }
        }

        setSelectedElement(elementInCenterIndex || 0);
      }
    };
    const containerRef = cardsContainerRef.current;
    if (containerRef) {
      containerRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef) {
        containerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const startCharging = useCallback(async () => {
    await axiosInstance
      .post(`/chargers/${chargerId}/connectors/${connectorId}/start`)
      .then(function (response) {
        const data = response.data;
        setSessionId(data.sessionID);
      })
      .catch(function (error) {
        const status = error.response?.status;
        switch (status) {
          default:
            setAuthMessage("An error occurred. Please try again.");
            // navigate("/404", { state: { chargerId: chargerId } });
            break;
        }
      });
  }, [chargerId, connectorId]);

  // const getPromoCode = () => {
  //   promoCode = page.context.invoice.promoCode;
  //   paymentIntentID = page.context.invoice.paymentIntentID;
  //   fetchSessionSummary();
  // };

  const stopCharging = async () => {
    await axiosInstance
      .delete(
        `/chargers/${chargerId}/connectors/${connectorId}/sessions/${sessionId}`,
      )
      .then((response) => {
        fetchSessionSummary();
      })
      .catch((error) => {
        const status = error.response?.status;
        switch (status) {
          default:
            setAuthMessage("An error occurred stopping the session.");
            break;
        }
        // navigate("/404", { state: { chargerId: chargerId } });
      });
  };

  const startChargingWithAuthCode = useCallback(
    async (authCode: string) => {
      await axiosInstance
        .post(
          `/chargers/${chargerId}/connectors/${connectorId}/start/${authCode}`,
        )
        .then((response) => {
          const data = response.data;
          setSessionId(data.sessionID);
          setHasStarted(true);
          setHasStopped(false);
          setAuthMessage(
            "Charging started successfully with authorization code!",
          );
        })
        .catch((error) => {
          const status = error.response?.status;
          switch (status) {
            case 400:
              setAuthMessage("Invalid authorization code. Please try again.");
              break;
            case 404:
              setAuthMessage("Charger not found. Please check the charger ID.");
              break;
            default:
              setAuthMessage("An error occurred. Please try again later.");
              break;
          }
        });
    },
    [chargerId, connectorId],
  );

  const handleStartCharging = useCallback(async () => {
    if (!hasStarted) {
      if (authCode.trim()) {
        // Start charging with authorization code
        startChargingWithAuthCode(authCode);
        return;
      }

      if (localStorage.getItem("guestId") !== null && paymentIntent !== "") {
        startCharging();
        setHasStarted(true);
        setHasStopped(false);
      } else if (localStorage.getItem("userId") !== null) {
        checkStripeAccount();
        if (customerID !== "") {
          startCharging();
        }

        setHasStarted(true);
        setHasStopped(false);
      } else {
        navigate("/payment", { state: { userLoggedIn: false } });
      }
    }
  }, [
    authCode,
    checkStripeAccount,
    customerID,
    hasStarted,
    navigate,
    paymentIntent,
    startCharging,
    startChargingWithAuthCode,
  ]);

  const handleStopCharging = () => {
    if (!hasStopped) {
      stopCharging(); // The API call
      setHasStopped(true); // Mark as called
      setHasStarted(false); // Reset the other flag
    }
  };

  const fetchSessionSummary = async () => {
    await axiosInstance
      .get(`sessions/${sessionId}`)
      .then((response) => {
        const data = response.data;
        let time = data.TotalTime;
        let date = new Date(data.TimestampStart).toLocaleString();
        let energy = data.TotalKW; // Total energy in kW

        let amountBeforeTaxes = data.TotalAmount; // Total amount before taxes
        let taxes = data.TotalTaxAmount; // Total tax amount
        let totalAfterTaxes = data.TotalWithTax; // Total amount after taxes
        let transactionCharges = 0; // Set to 0 as per the requirement
        let amountToCharge = data.TotalWithTax;

        if (authCode === "") {
          pageSend({
            type: "CHANGE_INVOICE",
            data: {
              invoice: {
                time: time,
                date: date,
                energy: energy,
                amountBeforeTaxes: amountBeforeTaxes,
                taxes: taxes,
                totalAfterTaxes: totalAfterTaxes,
                transactionCharges: transactionCharges,
                amountToCharge: amountToCharge,
              },
            },
          });
        }
        if (authCode) {
          pageSend({
            type: "CHANGE_INVOICE",
            data: {
              invoice: {
                time: time,
                date: date,
                energy: energy,
                amountBeforeTaxes: 0.0,
                taxes: 0.0,
                totalAfterTaxes: 0.0,
                transactionCharges: 0.0,
                amountToCharge: 0.0,
              },
            },
          });
        }
        navigate("/summary", { state: { chargerId: chargerId } });
      })
      .catch((error) => {
        const status = error.response?.status;
        switch (status) {
          default:
            setAuthMessage("An error occurred fetching session summary.");
            break;
        }
        // navigate("/404", { state: { chargerId: chargerId } });
      });
  };
  const mapStatus = (status: string): string => {
    // Map left OCPP status to user-readable status
    const statusMap: Record<string, string> = {
      Available: "Available",
      Preparing: "Preparing",
      Charging: "Charging",
      SuspendedEVSE: "Waiting for power",
      SuspendedEV: "EV Not Charging",
      Finishing: "Finishing",
      Reserved: "Reserved",
      Unavailable: "Unavailable",
      Faulted: "Out of Order",
    };

    return statusMap[status] || "Status Unavailable";
  };

  // getChargingState makes an api call to API service to fetch the current charger status.
  // The function has been memoized with useCallback() so that it only changes when the
  // chargerId or connectorId changes.
  const getChargingState = useCallback(() => {
    axiosInstance
      .get(`chargers/${chargerId}/connectors/${connectorId}/status`)
      .then((response) => {
        const currStatus = mapStatus(response.data.status);
        setCharger((currentCharger) =>
          currentCharger.map((chg) => ({
            ...chg,
            status: currStatus,
          })),
        );
      })
      .catch(function (error) {
        setCharger((currentCharger) =>
          currentCharger.map((chg) => ({
            ...chg,
            status: "Status Unavailable",
          })),
        );
      });
  }, [chargerId, connectorId]);

  useEffect(() => {
    if (chargerId && connectorId) {
      // Ensure the API call only happens if chargerId and connectorId are available
      getChargingState();
      // Set up polling to update status every 10 seconds
      const intervalId = setInterval(() => {
        getChargingState();
      }, 10000); // Fetch connector status every 10 seconds. TO DO: We will need to add an algorithm
      // which will fetch data depending on the connector status.

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [chargerId, connectorId, getChargingState]);

  // const doTransaction = async (totalAmount: any) => {
  //   await axiosInstance
  //     .post(`payments/capture`, {
  //       paymentIntentID: paymentIntentID,
  //     })
  //     .catch((error) => {
  //       navigate("/404", { state: { chargerId: chargerId } });
  //     });
  // };

  return (
    <div className="Home-layout" style={styles}>
      <div className="Homeprofile-notification">
        <div className="Homeprofile">
          <SubText text="Hello 👋"></SubText>

          <TitleText text="EV Driver"></TitleText>
        </div>

        <div className="Homenotification">
          <Logo className="Logo-large" />
        </div>
      </div>

      <SubTitle text="Charger" url="/Chargers"></SubTitle>

      <div className="Home-Cards" ref={cardsContainerRef}>
        {charger.map((item, index) => (
          <CardHome
            status={item.status}
            image={item.image}
            type={item.type}
            name={item.name}
            isSelect={index === selectedElement}
          ></CardHome>
        ))}
      </div>

      {active && (
        <p>Charging Session Duration (H:M): {formatTime(timeElapsed)}</p>
      )}

      <div className="AuthorizationCode-section">
        <InputText
          type="text"
          value={authCode}
          setValue={setAuthCode}
          label="Authorization Code"
          placeholder="Enter authorization code"
          styles={{ marginBottom: "15px" }}
        />
        {authMessage && <p className="auth-message">{authMessage}</p>}
      </div>

      <Slider
        active={active}
        setActive={setActive}
        activeActive={handleStartCharging}
        inactiveExecute={handleStopCharging}
      ></Slider>
      {/* <SecundaryButton text="Add Charger" style={{margin:"20px 0"}} icon={<Plus/>}></SecundaryButton> */}
    </div>
  );
};

export default Home;
