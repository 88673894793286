import React from 'react'

interface Props {
  className?: string
}

const ArrowBlue = ({className}:Props) => {
  return (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="9" viewBox="0 0 18 9" fill="none">
  <path d="M16.6224 8L10.192 1.56957C9.43255 0.810143 8.18986 0.810143 7.43043 1.56957L1 8" stroke="#0D98E5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}

export default ArrowBlue