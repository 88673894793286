import React from 'react';

const Key = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11" fill="none">
    <path d="M12.6667 5.16667C12.6667 7.46784 14.5321 9.33334 16.8333 9.33334C19.1345 9.33334 21 7.46784 21 5.16667C21 2.8655 19.1345 1 16.8333 1C14.5321 1 12.6667 2.8655 12.6667 5.16667Z" stroke="#85898F" strokeWidth="1.5"/>
    <path d="M12.667 5.1665H5.16699M5.16699 5.1665H2.66699C1.74649 5.1665 1.00032 5.91267 1.00032 6.83317V8.49984M5.16699 5.1665V7.6665" stroke="#85898F" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
    )
}

export default Key;