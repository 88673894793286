import React, { useState } from "react";
import "./GuestPaymentMethod.css";
import TitleText from "src/components/TitleText/TitleText";
import SubText from "src/components/SubText/SubText";
import InputText from "src/components/InputText/InputText";
import PrincipalButton from "src/components/PrincipalButton/PrincipalButton";
import { InputMessage } from "src/models/inputMessage";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate, useLocation, Link } from "react-router-dom";

import axiosInstance from "src/axiosInstance";

const PaymentMethod = () => {
  const location = useLocation();
  const { chargerId, userLoggedIn } = location.state || {};

  const [cardNumberStatus, setCardNumberStatus] = React.useState<boolean>(true);
  const [expirationStatus, setExpirationStatus] = React.useState<boolean>(true);
  const [cvvStatus, setCvvStatus] = React.useState<boolean>(true);

  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [promoCode, setPromoCode] = React.useState<string>("");
  const [promoMessages, setPromoMessages] = React.useState<InputMessage[]>([]);

  const [clientSecret, setClientSecret] = useState<string>("");
  const [paymentIntentId, setPaymentIntentId] = useState<string>("");
  const [guestId, setGuestId] = useState<string>("");

  const fetchGuestToken = async () => {
    try {
      const response = await axiosInstance.post("guest");
      const data = response.data;
      setClientSecret(data.client_secret);
      setPaymentIntentId(data.payment_intent_id);
      setGuestId(data.guest_id);
    } catch (error) {
      setErrorMessage("Failed to initialize payment. Please try again.");
    }
  };

  const createPaymentIntent = async () => {
    await axiosInstance
      .post("payments/intents")
      .then(function (response) {
        const data = response.data;
        setClientSecret(data.client_secret);
        setPaymentIntentId(data.payment_intent_id);
      })
      .catch(function (error) {
        setErrorMessage("Failed to initialize payment. Please try again.");
      });
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate promo code if entered
    if (promoCode !== "") {
      await axiosInstance
        .post(`/payments/discounts/${promoCode}`)
        .then(function (response) {
          const data = response.data;
          navigate("/", {
            state: { paymentIntentId: data.ID, loggedIn: true },
          });
          return;
        })
        .catch(function (error) {
          const status = error.response.status;
          switch (status) {
            case 400:
              setPromoMessages([{ message: "Promo Code invalid" }]);
              break;
            case 503:
              setPromoMessages([
                { message: "Internal server error. Please try again." },
              ]);
              break;
            case 500:
              setPromoMessages([
                { message: "Internal server error. Please try again." },
              ]);
          }
        });
      return;
    } else {
      if (!userLoggedIn) {
        await fetchGuestToken();
      } else {
        await createPaymentIntent();
      }

      // Validate card fields
      if (cardNumberStatus || expirationStatus || cvvStatus) {
        setErrorMessage("Please fill all the fields");
        return;
      }

      // Confirm card payment
      if (!stripe || !elements) {
        setErrorMessage("Stripe is not initialized");
        return;
      }

      try {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement) as any,
          },
        });

        if (result.paymentIntent?.status === "requires_capture") {
          navigate("/", { state: { paymentIntentId, guestId } });
          return;
        }
      } catch (error) {
        setErrorMessage("Payment failed. Please try again.");
        // navigate("/404", { state: { chargerId } });
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#141D2F",
        fontWeight: "400",
        fontFamily: "CustomPoppins, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#141D2F",
        },
        "::placeholder": {
          color: "#85898F",
        },
      },
    },
  };

  return (
    <div className="PaymentMethod-layout">
      <TitleText text="Checkout" styles={{ marginBottom: "0" }}></TitleText>
      <SubText
        text="Please add your credit card information or a promo code."
        styles={{ marginBottom: "40px" }}
      ></SubText>
      <form onSubmit={handleSubmit} className="formPaymentMethod">
        <InputText
          messages={promoMessages}
          value={promoCode}
          setValue={setPromoCode}
          type="text"
          label="Use Promo Code"
          placeholder="Promo Code"
          styles={{ marginTop: "15px", marginBottom: "15px" }}
        />

        {!(promoCode !== "") && (
          <>
            <label className="label-payment">Card Number</label>
            <div
              style={{
                border: "1px solid #E7E7E9",
                borderRadius: "10px",
                padding: "20px 4px",
                marginBottom: "12px",
              }}
            >
              <CardNumberElement
                onChange={(e) => {
                  setCardNumberStatus(e.empty);
                }}
                options={CARD_ELEMENT_OPTIONS}
              ></CardNumberElement>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <label className="label-payment">Expiration</label>
                <div
                  style={{
                    border: "1px solid #E7E7E9",
                    borderRadius: "10px",
                    padding: "20px 4px",
                    marginBottom: "12px",
                  }}
                >
                  <CardExpiryElement
                    onChange={(e) => {
                      setExpirationStatus(e.empty);
                    }}
                    options={CARD_ELEMENT_OPTIONS}
                  ></CardExpiryElement>
                </div>
              </div>
              <div style={{ width: "45%" }}>
                <label className="label-payment">CVC</label>
                <div
                  style={{
                    border: "1px solid #E7E7E9",
                    borderRadius: "10px",
                    padding: "20px 4px",
                    marginBottom: "12px",
                  }}
                >
                  <CardCvcElement
                    onChange={(e) => {
                      setCvvStatus(e.empty);
                    }}
                    options={CARD_ELEMENT_OPTIONS}
                  ></CardCvcElement>
                </div>
              </div>
            </div>
            <SubText
              text="Temporary 20$ CAD hold will be put on your card."
              styles={{ marginBottom: "40px" }}
            ></SubText>
          </>
        )}

        {errorMessage !== "" && (
          <div className="error-Message">
            <span>{errorMessage}</span>
          </div>
        )}

        <PrincipalButton
          type="submit"
          text="Add"
          version="blue"
          chargerId={chargerId}
          styles={{ marginBottom: "53px", marginTop: "15px", width: "100%" }}
        ></PrincipalButton>
      </form>
      <span className="simpleText">
        Already have an account?{" "}
        <b>
          <Link to="/login">Sign In</Link>
        </b>
      </span>
    </div>
  );
};

export default PaymentMethod;
