import React, { useState } from "react";
import TitleText from "../TitleText/TitleText";
import SubText from "../SubText/SubText";

//style
import "./RegisterAccount.css";
import InputText from "../InputText/InputText";
import PrincipalButton from "../PrincipalButton/PrincipalButton";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "src/axiosInstance";

interface Props {
  nextStep: () => void;
}

const RegisterAccount = ({ nextStep }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");

  const location = useLocation();
  const { chargerId } = location.state || {};

  const registerUser = async (event: any) => {
    event.preventDefault();
    // Send the login request using the axiosInstance
    await axiosInstance
      .post("/register", {
        email,
        password,
        firstName,
        lastName,
      })
      .then(function (response) {
        if (response.status === 200) {
          setMessage("Registration successfull!! Please verfiy your Email before logging in!!.");
        }
      })
      .catch(function (error) {
        const status = error.response.status;
        switch (status) {
          case 409:
            setMessage("Email already in use. Try logging in.");
            break;
          case 503:
            setMessage("Server unavailable. Pleae try again later.");
            break;
          case 500:
            setMessage("Internal server error. Please try again later.");
        }
      });
  };

  return (
    <div className="RegisterAccount-layout">
      <TitleText
        text="Register Account"
        styles={{ marginBottom: "26px" }}
      ></TitleText>
      <SubText
        text="Enter your name, email address & password to register account."
        styles={{ marginBottom: "40px" }}
      ></SubText>
      <form onSubmit={registerUser}>
        <div className="splitDiv">
          <div style={{ width: "40%" }}>
            <InputText
              type="text"
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder="Jiaro"
              styles={{ marginBottom: "31px" }}
            />
          </div>

          <div style={{ width: "40%" }}>
            <InputText
              type="text"
              value={lastName}
              setValue={setLastName}
              label="Last Name"
              placeholder="Martins"
              styles={{ marginBottom: "31px" }}
            />
          </div>
        </div>
        <InputText
          type="email"
          value={email}
          setValue={setEmail}
          label="Email"
          placeholder="hello@ilovedrivinggreen.com"
          styles={{ marginBottom: "31px" }}
        />
        <InputText
          type="password"
          label="Password"
          value={password}
          setValue={setPassword}
          placeholder="*******"
          styles={{ marginBottom: "20px" }}
        />
        {message && (
          <p style={{ color: "red", marginBottom: "20px" }}>{message}</p>
        )}
        <PrincipalButton
          type={"submit"}
          text="Continue"
          version="blue"
          styles={{ marginBottom: "53px" }}
        ></PrincipalButton>
      </form>

      <span className="simpleText">
        By signing up you agree with our friendly
        <b>
          <a href="https://jplug.io/"> terms and conditions.</a>
        </b>
      </span>

      <span className="simpleText">
        Already have an account?
        <b>
          <Link to="/Login" state={{ chargerId }}>
            Sign In
          </Link>
        </b>
      </span>
    </div>
  );
};

export default RegisterAccount;
