import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/axiosInstance";
import HeaderBack from "src/components/HeaderBack/HeaderBack";

import "./Charger.css";

const Charger = () => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setInputValue(value);
      setErrorMessage("");
      if (value.length === 6) {
        const chargerId = `JPLUG-${value}`;
        try {
          await axiosInstance.get(`/chargers/${chargerId}`);
          localStorage.setItem("chargerName", chargerId);
          navigate(`/${chargerId}`, { state: { chargerName: chargerId } });
        } catch (error: any) {
          setErrorMessage("Charger not found. Please try again.");
        }
      }
    }
  };

  const charger = {
    img: "/image/fractal2.png",
    name: "Fractal Charger",
    available: true,
  };

  return (
    <div className="Charger-layout">
      <HeaderBack title="Chargers" />
      <div className="Charger-Card">
        <div className="ChargerCard-Component">
          <img alt="charger" src={charger.img} />
        </div>
        <div className="Charger-input-container">
          {errorMessage && <p className="Charger-error">{errorMessage}</p>}
          <div className="Charger-input-wrapper">
            <span>JPLUG-</span>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter 6 digits"
              maxLength={6}
              className="Charger-input"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charger;
