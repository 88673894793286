import React, { useEffect } from 'react';
import './App.css';

import { BrowserRouter as Router, Route , Routes} from 'react-router-dom';
import { useMachine } from '@xstate/react';

//components
import Layout from './layout/Layout';
import Splashscreen from './pages/Splashscreen/Splashscreen';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import PlugTime from './pages/PlugTime/PlugTime';
import AddCharger from './pages/AddCharger/AddCharger';
import PaymentMethod from './pages/PaymentMethod/PaymentMethod';
import MoneyReceived from './pages/MoneyReceived/MoneyReceived';
import SessionSummary from './pages/SessionSummary/SessionSummary';
import MyAccount from './pages/MyAccount/MyAccount'
import BillingAddress from './pages/BillingAddress/BillingAddress';
import SettingsPaymentMethod from './pages/SettingsPaymentMethod/SettingsPaymentMethod';
import AllSettings from './pages/AllSettings/AllSettings';
import AccountProfile from './pages/AccountProfile/AccountProfile';
import Notifications from './pages/Notifications/Notifications';
import Charger from './pages/Charger/Charger';
import ChargerSettings from './pages/ChargerSettings/ChargerSettings';
import Home from './pages/Home/Home';
import CongratsView from './pages/CongratsView/CongratsView';

//pages
import { pageMachine, PromisePageContext } from './machines/pageMachine';
import ErrorView from './pages/ErrorView/ErrorView';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import GuestPaymentMethod from './pages/GuestPaymentMethod/GuestPaymentMethod';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';



function App() {
  const [page, pageSend] = useMachine(pageMachine);
  

  useEffect(() => {
    const handlePopState = () => {
      const desiredPath = '/chargerId';
      const currentPath = window.location.pathname;

      if (currentPath !== desiredPath) {
        window.location.href = desiredPath;
      }
      
    
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (

    <>
      <Router>
      <PromisePageContext.Provider value={{page, pageSend}}>

        <Layout>
        <Routes>
          <Route path="/Splashscreen" element={<Splashscreen/>}></Route>
          <Route path="/" element={<Splashscreen/>}></Route>
          <Route path="/login" element={<Login/>}></Route>
          <Route path="/register" element={<SignUp/>}></Route>
          <Route path="/verify-email" element={<VerifyEmail/>}></Route>
          <Route path="/PlugTime" element={<PlugTime/>}></Route>
          <Route path="/AddCharger" element={<AddCharger/>}></Route>
          <Route path="/add-payment" element={<PaymentMethod/>}></Route>
          <Route path="/summary" element={<MoneyReceived/>}></Route>
          <Route path="/sessions" element={<SessionSummary/>}></Route>
          <Route path="/MyAccount" element={<MyAccount/>}></Route>
          <Route path="/BillingAddress" element={<BillingAddress/>}></Route>
          <Route path="/SettingsPaymentMethod" element={<SettingsPaymentMethod/>}></Route>
          <Route path="/AllSettings" element ={<AllSettings/>}></Route>
          <Route path="/AccountProfile" element={<AccountProfile/>}></Route>
          <Route path="/Notifications" element={<Notifications/>}></Route>
          <Route path="/charger" element={<Charger/>}></Route>
          <Route path="/ChargerSettings" element={<ChargerSettings/>}></Route>
          <Route path="/:chargerName" element={<Home/>}></Route>
          <Route path="/congrats" element={<CongratsView/>}></Route>
          <Route path="/404" element={<ErrorView/>}></Route>
          <Route path="/payment" element={<GuestPaymentMethod/>}></Route>
          <Route path="*" element={<ErrorView/>}></Route>

        </Routes>
        </Layout>
        </PromisePageContext.Provider>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;


