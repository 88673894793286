import React from "react";


const PaymentMethod = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
            <path d="M1 6H10.4964M4.99849 14H6.99774M9.4968 14H13.4953" stroke="#232A35" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9925 9.53V13.61C20.9925 17.12 20.1028 18 16.5541 18H5.43833C1.88966 18 1 17.12 1 13.61V5.39C1 1.88 1.88966 1 5.43833 1H13.4953" stroke="#232A35" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.0738 1.62982L14.3652 5.33982C14.2252 5.47982 14.0853 5.75982 14.0553 5.95982L13.8554 7.37982C13.7854 7.88982 14.1453 8.24982 14.6551 8.17982L16.0745 7.97982C16.2745 7.94982 16.5544 7.80982 16.6943 7.66982L20.4029 3.95982C21.0427 3.31982 21.3426 2.57982 20.4029 1.63982C19.4533 0.689817 18.7136 0.989817 18.0738 1.62982Z" stroke="#232A35" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5439 2.16016C17.8639 3.29016 18.7439 4.17016 19.8639 4.48016" stroke="#232A35" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default PaymentMethod;