import React from 'react'

const Warning = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 8.25V14.25M12 17.25V18.75M12 2.25L22.5 21.75H1.5L12 2.25Z" stroke="#F5BD62" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Warning