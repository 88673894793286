import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import axiosInstance from "src/axiosInstance";

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading",
  );
  const [message, setMessage] = useState("");
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      setStatus("error");
      setMessage("Invalid or missing token.");
      return;
    }
    const verifyEmail = async () => {
      try {
        const response = await axiosInstance.get(`/verify-email/${token}`);
        if (response.status === 200) {
          setStatus("success");
          setMessage("Your email has been successfully verified!");
          return;
        } else {
          setStatus("error");
          setMessage("Invalid or expired token.");
        }
      } catch (error) {
        setStatus("error");
        setMessage("An error occurred while verifying your email.");
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {status === "loading" && <p>Verifying your email...</p>}
      {status === "success" && (
        <div>
          <p>{message}</p>
          <Link to="/login">Go to Login</Link>
        </div>
      )}
      {status === "error" && (
        <div>
          <p>{message}</p>
          <Link to="/register">Register Again</Link>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
