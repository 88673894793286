import React from 'react'

interface Props {
    className?: string
  }

const Logo = ({className}:Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
    <path d="M10.8791 0.664571V13.4004H14.5803V9.45844H17.3134C18.6165 9.45844 19.6509 9.02251 20.4166 8.15052C21.1819 7.27891 21.5648 6.19847 21.5648 4.90989C21.5648 3.62099 21.1568 2.59149 20.3406 1.82053C19.5245 1.05012 18.3127 0.664571 16.706 0.664571H10.8791ZM13.9889 4.99417C13.9889 3.44378 15.2476 2.18693 16.8002 2.18693C18.3529 2.18693 19.6116 3.44378 19.6116 4.99417C19.6116 6.54462 18.3529 7.80147 16.8002 7.80147C15.2476 7.80147 13.9889 6.54462 13.9889 4.99417ZM7.16418 0.69847L4.24701 5.34372H6.54582L5.76588 9.58298L9.83666 3.53973H7.8555L9.7695 0.69847H7.16418ZM17.2393 4.21518C17.2393 4.60276 17.5539 4.91699 17.9421 4.91699C18.3303 4.91699 18.645 4.60276 18.645 4.21518C18.645 3.82753 18.3303 3.51337 17.9421 3.51337C17.5539 3.51337 17.2393 3.82753 17.2393 4.21518ZM14.9555 4.21518C14.9555 4.60276 15.2701 4.91699 15.6583 4.91699C16.0464 4.91699 16.3611 4.60276 16.3611 4.21518C16.3611 3.82753 16.0464 3.51337 15.6583 3.51337C15.2701 3.51337 14.9555 3.82753 14.9555 4.21518ZM17.7237 5.67499C17.7237 5.92296 17.9251 6.124 18.1733 6.124C18.4217 6.124 18.6231 5.92296 18.6231 5.67499C18.6231 5.42695 18.4217 5.2259 18.1733 5.2259C17.9251 5.2259 17.7237 5.42695 17.7237 5.67499ZM14.9773 5.67499C14.9773 5.92296 15.1787 6.124 15.427 6.124C15.6754 6.124 15.8767 5.92296 15.8767 5.67499C15.8767 5.42695 15.6754 5.2259 15.427 5.2259C15.1787 5.2259 14.9773 5.42695 14.9773 5.67499ZM6.8221 9.73932H6.81363L0.192383 9.76167V13.4004H6.23948C8.22614 13.4004 9.83666 11.7923 9.83666 9.80854V5.40713L6.8221 9.73932ZM16.0974 6.29961C16.0974 6.68719 16.412 7.00142 16.8002 7.00142C17.1884 7.00142 17.5031 6.68719 17.5031 6.29961C17.5031 5.91197 17.1884 5.59774 16.8002 5.59774C16.412 5.59774 16.0974 5.91197 16.0974 6.29961Z" fill="url(#paint0_linear_9884_1013)"/>
    <defs>
    <linearGradient id="paint0_linear_9884_1013" x1="10.8786" y1="13.4004" x2="10.8786" y2="0.664557" gradientUnits="userSpaceOnUse">
    <stop stopColor="#1E75BB"/>
    <stop offset="1" stopColor="#00B8EB"/>
    </linearGradient>
    </defs>
    </svg>
  )
}

export default Logo