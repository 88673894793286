import React from "react";

const LogOut = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M10.6 14.275L13.8 11.075L10.6 7.875M1 11.075H13.7125" stroke="#232A35" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 1C16.525 1 21 4.75 21 11C21 17.25 16.525 21 11 21" stroke="#232A35" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default LogOut;